<template>
  <v-col
    cols="12"
    class="prevent-trigger-update"
  >
    <v-row
      v-if="isCatalogsPage"
    >
      <v-col
        class="d-flex justify-start"
      >
        <v-btn
          class="mr-4"
          height="56px"
          x-large
          disabled
          @click="showForm"
        >
          <v-icon>mdi-plus</v-icon>
          Добавить запись
        </v-btn>

        <v-text-field
          v-debounced
          :value="searchValue"
          hide-details="auto"
          background-color="#EDEDED"
          class="ba-0 rounded-l rounded-r"
          filled
          rounded
          full-width
          placeholder="Поиск"
          clearable
          :readonly="loading"
          @input="onInput"
          @click:clear="() => (searchValue = '')"
        />
      </v-col>
    </v-row>
    <v-text-field
      v-else
      v-debounced
      :value="searchValue"
      hide-details="auto"
      background-color="#EDEDED"
      class="ba-0 rounded-l rounded-r"
      filled
      rounded
      full-width
      placeholder="Поиск"
      clearable
      :readonly="loading"
      @input="onInput"
      @click:clear="() => (searchValue = '')"
    />

    <v-simple-table class="mt-2">
      <thead>
        <tr>
          <th
            v-for="header in headers"
            :key="`header-${header.value}`"
          >
            {{ header.name }}
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, idx) in vehicles"
          :key="'type-item-' + idx"
        >
          <td
            v-for="header in headers"
            :key="`type-item-${idx}-${header.value}`"
          >
            <template
              v-if="header.value === 'number'"
            >
              <span @click="select(item)">
                <text-highlight
                  :queries="searchValue || ''"
                  class="code-txt"
                >
                  {{
                    item[header.value]
                  }}
                </text-highlight>
              </span>
            </template>
            <template v-else>
              <text-highlight
                :queries="searchValue || ''"
              >
                {{
                  item[header.value]
                }}
              </text-highlight>
            </template>
          </td>
          <td v-if="isCatalogsPage">
            <div class="d-flex justify-end">
              <v-btn
                width="36px"
                min-width="36px"
                class="mr-4"
                :disabled="item.user_id === userId ? false : !$can('cat.org.update')"
                @click="edit(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                width="36px"
                min-width="36px"
                color="red"
                :disabled="item.user_id === userId ? false : !$can('cat.org.delete')"
                @click="remove(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-col>
</template>
<script>
import {mapGetters} from 'vuex';
import moment from "moment";

export default {
  props: {
    search: {
      validator: (prop) => typeof prop === "string" || prop === null,
      default: "",
    },
  },
  data: () => ({
    loading: false,
    searchValue: "",
    vehicles: [],
    headers: [
      {
        name: 'Фамилия',
        value: 'last_name'
      },
      {
        name: 'Имя',
        value: 'first_name'
      },
      {
        name: "Отчество",
        value: "middle_name"
      },
      {
        name: "Страна",
        value: "country_letter"
      },
      {
        name: "Код документа",
        value: "identity_doc_code"
      },
      {
        name: "Номер документа",
        value: "identity_doc_number"
      },
      {
        name: "Дата документа",
        value: "date"
      },

    ]
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    }),
    userId() {
      return this.user?.user?.id
    },
    isCatalogsPage() {
      return this.$route.name === 'CatalogsHomepage'
    },
    divisionId() {
      return this.user?.user?.activeDivisionId || null
    },
  },
  watch: {
    search: {
      handler(nv) {
        this.searchValue = nv;
      },
      immediate: true,
    },
  },
  created() {
    this.loadDrivers()
  },
  methods: {
    onInput(value) {
      if (this.loading) return;
      this.searchValue = value
      this.searchDrivers()
    },
    select(item) {
      this.$emit('select', item)
    },
    searchDrivers() {
      if (this.loading) return false;
      this.loading = true
      this.$store.dispatch("catalogs/searchDriver", {
        value: this.searchValue,
        divisionId: this.divisionId
      }).then(res => {
        this.vehicles = this.modifyResponse(res.data)
      }).catch(() => {
        this.$snackbar({text: 'Ошибка загрузки', top: false, right: false, color: 'red'})
      }).finally(() => {
        this.loading = false
      })
    },
    modifyResponse(data) {
      return data.map(item => {
        return {
          ...item,
          date: moment(item.identity_doc_date).isValid() ? moment(item.identity_doc_date).format('DD.MM.YYYY') : null
        }
      })
    },
    loadDrivers() {
      if (this.loading) return false;
      this.loading = true
      this.$store.dispatch("catalogs/fetchDriversDivision", {
        divisionId: this.divisionId
      }).then(res => {
        this.vehicles = this.modifyResponse(res.data)
      }).catch(() => {
        this.$snackbar({text: 'Ошибка загрузки', top: false, right: false, color: 'red'})
      }).finally(() => {
        this.loading = false
      })
    },
    showForm() {
      this.$emit('perform', {component: 'driversForm'})
    },
    edit(item) {
      if (this.isCatalogsPage) {
        this.$emit('perform', {component: 'driversForm', item})
      }
    },
    remove({id}) {
      this.$store.dispatch("catalogs/deleteDriver", {id}).then(() => {
        this.$snackbar({text: 'Запись удалена', top: false, right: false,})
        this.loadDrivers()
      }).catch(() => {
        this.$snackbar({text: 'Ошибка', top: false, right: false, color: 'red'})
      })
    }
  }
}
</script>